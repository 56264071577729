/* eslint-disable no-param-reassign, class-methods-use-this */

'use strict';

define('vb/private/vx/baseExtensionRegistry',[
  'vb/private/vx/extensionAdapterFactory',
  'vb/private/vx/appUiInfos',
  'vb/private/vx/baseExtension',
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/utils',
  'urijs/URI',
  'vb/private/log',
], (ExtensionAdapterFactory, AppUiInfos, BaseExtension, ConfigLoader, Constants, Utils, URI, Log) => {
  const logger = Log.getLogger('/vb/private/vx/baseExtensionRegistry');

  /**
   * Given 2 arrays, replace or append element from source array to
   * target array when the id of the element matches
   *
   * @param  {Array} sourceArray
   * @param  {Array} targetArray
   */
  const replaceOrAppendToArray = (sourceArray, targetArray) => {
    if (Array.isArray(sourceArray) && Array.isArray(targetArray)) {
      sourceArray.forEach((info) => {
        let index = 0;
        // Search for the object in targetArray with info.id
        for (; index < targetArray.length; index += 1) {
          if (targetArray[index].id === info.id) {
            break;
          }
        }

        // Either replace or append it with the source value
        targetArray.splice(index, 1, info);
      });
    }
  };

  /**
   * A class to retrieve the extensions for the current application from the extension manager
   * The extension manager URL is defined in the app-flow.json under the extension property.
   */
  class BaseExtensionRegistry {
    /**
     * This is called from ConfigLoader.js
     *
     * @param  {function} applicationConfigCallback a callBack that returns a promise to the application config
     * @param  {function} runtimeEnvironmentCallback a callBack that returns a promise to the runtime environment
     */
    constructor(applicationConfigCallback, runtimeEnvironmentCallback) {
      this.applicationConfigCallback = applicationConfigCallback;
      this.runtimeEnvironmentCallback = runtimeEnvironmentCallback;

      this.fetchManifestPromise = null; // Initialized in subclass initiateLoadManifest()
      this.loadExtensionsPromise = null; // Initialized in getExtensions()

      /**
       * A map of extension object keyed by their id
       * @type {Object}
       */
      this.extensions = {};

      this.log = logger;
    }

    static get extensionManagerVersion() {
      throw Error('need to override extensionManagerVersion');
    }

    /**
     * Initialize the extension registry. This consist of defining promise responsible
     * to load the manifest
     * @return {Promise}
     */
    initialize() {
      return this.initiateLoadManifest().then((result) => {
        if (result === false) {
          // If initiateLoadManifest return false, it means there is no extension manager defined
          // for this application so the manifest is an empty array.
          this.loadExtensionsPromise = Promise.resolve([]);
          this.log.info('No extension registry defined.');
        }
      });
    }

    /**
     * Load extension manager and design time manifest and merge them
     * Only called by subclass
     * @return {Promise<Object>} a promise to a manifest
     */
    _loadManifest() {
      return Promise.resolve().then(() => {
        const promises = [
          this.fetchManifestPromise,
          this.runtimeEnvironmentCallback().then((re) => re.getExtensionManifest()),
        ];
        // Load the manifest from the extension manager and from DT and replace
        // the extensions and requirejsInfo with the ones from the DT manifest.
        return Promise.all(promises).then((manifests) => {
          const manifest = manifests[0];
          const dtManifest = manifests[1];
          if (dtManifest) {
            // Replace or append manifest extensions using DT manifest
            replaceOrAppendToArray(dtManifest.extensions, manifest.extensions);

            // Replace or append manifest requirejsInfo using DT extensions requirejsInfo
            replaceOrAppendToArray(dtManifest.requirejsInfo, manifest.requirejsInfo);

            // Replace or append manifest appUiInfo using DT extensions appUiInfo
            replaceOrAppendToArray(dtManifest.appUiInfo, manifest.appUiInfo);
          }

          // Remove the loading promise since it's not needed anymore
          this.fetchManifestPromise = null;

          return manifest;
        });
      });
    }

    /**
     * Retrieve the adapter to be used to load the extensions
     * If no extension manager is defined, return an undefined adapter
     * @return {Promise} a promise that resolve to an adapter
     */
    getExtensionAdapter() {
      return ExtensionAdapterFactory.get(this.constructor.extensionManagerVersion);
    }

    /**
     * Calculate the base path of the extended resource given the container
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePath(path, container = { extensionId: '' }) {
      return `${container.extensionId}/${path}`;
    }

    /**
     * Calculate the base path for ui type of resource (stuff under ui/)
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line no-unused-vars
    getBasePathForUi(path, container) {
      // implemented by subclass
    }

    /**
     * Calculate the base path for layout type of resource (stuff under dynamicLayouts/)
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line no-unused-vars
    getBasePathForLayout(path, container) {
      // implemented by subclass
    }

    /**
     * Get the base path for the given container. If the container is a layout,
     * getBasePathForLayout will be called, otherwise, getBasePathForUi is called.
     *
     * @param path
     * @param container
     * @returns {string}
     */
    getBasePathForContainer(path, container) {
      return path.startsWith(Constants.DefaultPaths.LAYOUTS) ? this.getBasePathForLayout(path, container)
        : this.getBasePathForUi(path, container);
    }

    /**
     * Loads all the extension for a specific container given its path. It returns a promise
     * that resolves in an array of extensions object, either PageExtension or FlowExtension.
     * @param  {String} path the path of the object for which we are looking for extensions
     * @param  {Container} container the container for which the extension is being loaded
     * @return {Promise} a promise to an array of extension objects
     */
    loadContainerExtensions(path, container) {
      return this.getExtensions()
        .then((extensions) => {
          if (extensions.length === 0) {
            return [];
          }

          const promises = [];
          const basePath = this.getBasePathForContainer(path, container);
          const Clazz = container.constructor.extensionClass;
          // container name may not necessarily be the actual resourceName. So use the resourceName property instead
          const extPath = `${basePath}${container.extensionResourceName}${Clazz.resourceSuffix}`;

          // Traverse the array of extension from first to last. The extension manager is responsible
          // for properly ordering this array of extensions given the dependencies in the extension manager.
          extensions.forEach((extension) => {
            const files = extension.files || [];

            // If the manifest contains an extension for this artifact, creates an extension object for it
            if (files.indexOf(extPath) >= 0) {
              // TODO: reduce params?
              const ext = new (Clazz)(extension, basePath, container);
              const promise = ext.load().then(() => ext);
              promises.push(promise);
            }
          });

          // All files are then loaded in parallel
          return Promise.all(promises);
        });
    }

    /**
     * Retrieve a map of AppUiInfo for all the App UI available in all the extensions
     * The map is populated only by the v2 implementation
     * @return {Promise} a promise that resolve with a map of AppUiInfo
     */
    getAppUiInfos() {
      return Promise.resolve(new AppUiInfos());
    }

    /**
     * Returns a promise that resolves with an array of extensions or an empty array
     * @return {Promise<Array>} a promise that resolve to an array of extension
     */
    getExtensions() {
      this.loadExtensionsPromise = this.loadExtensionsPromise || this.getLoadManifestPromise().then((manifest) => {
        const extensions = [];

        (manifest.extensions || []).forEach((definition) => {
          const extensionId = definition.id;
          const extension = this.createExtension(definition,
            manifest.appUiInfo && manifest.appUiInfo[extensionId],
            manifest.bundlesInfo && manifest.bundlesInfo[extensionId],
            manifest.bundledResources && manifest.bundledResources[extensionId],
            manifest.components && manifest.components[extensionId]);

          if (!extension.isValid()) {
            this.log.error('Invalid manifest for extension:', extensionId, 'version:', extension.version);
          } else {
            extensions.push(extension);
            this.extensions[extensionId] = extension;
          }
        });

        const promises = extensions
          // if this extension extends the application, load the bundle or
          // create a require mapping so the resources are available.
          // Extensions with only App UI definition are loaded on demand.
          .filter((extension) => extension.extendsBaseArtifact())
          .map((extension) => extension.init());

        return Promise.all(promises).then(() => extensions);
      });

      return this.loadExtensionsPromise;
    }

    /**
     * Look up the extension identified by id.
     *
     * @param id extension id
     * @returns {Extension}
     */
    getExtensionById(id) {
      return this.extensions[id];
    }

    /**
     * This function checks if an extension depends directly or indirectly on another extension given their ids.
     *
     * @param {string} extensionId
     * @param {string} upstreamExtensionId
     * @returns {boolean} true if extensionId depends on upstreamExtensionId
     * @abstract
     */
    // eslint-disable-next-line no-unused-vars
    isDependent(extensionId, upstreamExtensionId) {
      throw Error('need to override isDependent');
    }

    /**
     * Add requirejs mappings
     * @param {Object} paths
     */
    addRequireMapping(paths) {
      ConfigLoader.setConfiguration({ paths });
    }

    /**
     * Creates an ExtensionServices Object for the application extension, and creates a name/file map
     * from the contents of the extension.
     *
     * @param {String} extensionId
     * @param {Object} options the standard options used to construct a Services object.
     *
     * @returns {Promise}
     */
    loadServicesModel(extensionId, options) {
      // populated with the services we can find on the extension manifest
      const extensionServiceMap = {};

      return this.getExtensions()
        .then((extensions) => {
          const ext = extensions.find((ex) => ex.id === extensionId);
          if (ext) {
            ext.files.forEach((file) => {
              let name;
              let path;

              const match = file.match(this.constructor.serviceRegex);
              // [0] is the whole match, [1] is the first (and only) group
              if (match) {
                path = match[0];
                name = match[1];
              }

              if (name && path) {
                // we need to check if the extension has an explicit serviceFileMap declaration to ensure we are
                // not replacing it
                const declaredPath = options && options.serviceFileMap && options.serviceFileMap[name];
                if (!declaredPath) {
                  extensionServiceMap[name] = path;
                } else if (declaredPath !== path) {
                  // it would be weird to declare a path for a service that doesn't match its name,
                  // if one that did match its name already existed.
                  this.log.warn('Extension', extensionId, 'contains service metadata ', path,
                    '. The declared file will be used instead: ', declaredPath);
                }
              }
            });
          } else {
            // this should never happen
            this.log.warn('Unable to find extension services for extension, continuing: ', ext);
          }
          return this.findCatalog(ext);
        })
        .then((catalogPath) => {
          const optionsClone = Object.assign({ extensionServiceMap }, options);

          if (catalogPath) {
            optionsClone.extensions = optionsClone.extensions || {};
            optionsClone.extensions.catalogPaths = {
              [extensionId]: catalogPath,
            };
          }

          // ExtensionServices need to be loaded later than this module because
          // it forces JET to load before ojL10n is setup in ConfigLoader
          return Utils.getResource('vb/private/services/extensionServices')
            .then((ExtensionServices) => new ExtensionServices(optionsClone));
        });
    }

    /**
     * if there is a catalog.json in self/, returns the vx-mapped path to the file
     * ex:  vx/ext2/self/services/catalog.json
     * @param {Object} extension
     * @returns {string|undefined}
     */
    findCatalog(extension) {
      let found;
      extension.files.some((file) => {
        if (this.constructor.catalogRegex.test(file)) {
          found = `${Constants.EXTENSION_PATH}${extension.id}/${file}`;
        }
        return !!found;
      });
      return found;
    }

    /**
     * Loads all the translation extensions for a specific Bundle given its path.
     * The default implementation which is used by v1 does not support translation extensions so returns
     * an empty Promise.  The v2 implementation in extensionRegistry.js retrieves the translation extensions for
     * the bundle
     * @param  {String} path the path of the Bundle Definition for which we are looking for extensions
     * @param  {Object} bundleDefinition the bundle for which the extensions are being loaded
     * @return {Promise<Array>} a promise to an array of Bundle Extension objects
     */
    // eslint-disable-next-line no-unused-vars
    loadTranslationExtensions(path, bundleDefinition) {
      return Promise.resolve([]);
    }

    /*
     * Retrieve a map of all extensions that define translation bundles.
     * The default implementation which is used by v1 does not understand translations bundles
     * so returns an empty map. The v2 implementation in extensionRegistry.js retrieves
     * a Map of extensions that define a translation bundle.
     * @return {Promise<Map<string,object>>} map of extId to extension for all that define a translation bundle
     */
    getTranslations() {
      return Promise.resolve([]);
    }
  }

  return BaseExtensionRegistry;
});

