'use strict';

define('vb/private/services/services',[
  'urijs/URI',
  'vb/private/constants',
  'vb/private/log',
  'vb/binding/expression',
  'vb/private/utils',
  'vb/private/services/serviceProvider',
  'vb/private/services/serviceUtils',
  'vb/private/services/endpointReference',
  'vb/private/services/definition/programmaticPluginFactory',
  'vb/private/services/definition/serviceMapFactory',
  'vb/private/services/definition/serviceProviderFactory',
], (
  URI,
  Constants,
  Log,
  Expression,
  Utils,
  ServiceProvider,
  ServiceUtils,
  EndpointReference,
  ProgrammaticPluginFactory,
  ServiceMapFactory,
  ServiceProviderFactory,
) => {
  const logger = Log.getLogger('/vb/private/services/services');

  /**
   * Services
   */
  class Services {
    /**
     * @param {Object} options contains the following properties (all required, except where noted):
     * @param {string} [options.namespace='base']
     * @param {string} [options.relativePath='']
     * @param {Object} [options.serviceFileMap={}] map of service name to service definition file
     *                                      { myService: 'my-service.json', anotherService: 'blah/openapi3.json', <etc>}
     * @param {boolean} [options.isUnrestrictedRelative=false]
     * @param {Object} options.expressionContext (also known as 'scope') the dollar vars available to path expressions
     * @param {ProtocolRegistry} options.protocolRegistry handing 'vb-catalog',
     *                                                    for layering local metadata on service defs
     * @param {string[]} [options.serviceDefTypes=['openApi', 'serviceProvider']]
     */
    constructor(options) {
      const {
        namespace,
        relativePath,
        serviceFileMap = {},
        isUnrestrictedRelative,
        expressionContext,
        protocolRegistry,
        extensionRegistry,
      } = options;

      // this is set namespace when it is both undefined and an empty string
      this._namespace = namespace || Constants.ExtensionNamespaces.BASE;

      this._relativePath = relativePath || '';

      Utils.removeDecorators(serviceFileMap); // TODO: add unit test for this line

      // path values now support expressions
      this._expressionContext = expressionContext;

      // factored out of constructor so tests can reset the services
      this._options = options;
      this._initServiceDefFactories(options);

      this._protocolRegistry = protocolRegistry;
      this._extensionRegistry = extensionRegistry;

      // means this is declared in the Application, and treats paths differently
      this._isUnrestrictedRelative = !!isUnrestrictedRelative;

      // ServiceUtils.createNamespaceMap() return  a simple map-like data structure,
      // that takes 'id' and 'namespace' as params for the 'key'.
      this._loadedServicePromises = ServiceUtils.createNamespaceMap(this.namespace);

      this._fetchedServiceMetadataPromises = {};

      // Ordered collection of services to which this Services delegates. In other words,
      // if a service is not found on this Services, it searches for it on the Services
      // from this array, in the order they show here.
      /**
       * @type {Services[]}
       */
      this._delegates = [];
    }

    /**
     * Exposed so that unit tests can call it to reset service definition factories.
     * @param {*} options
     * @private
     */
    _initServiceDefFactories(options = (this._options || {})) {
      this._serviceDefFactories = {};
      // keep types in order as it determens how services are found
      this._serviceDefTypes = this.getServiceDefTypes(options.serviceDefTypes);
      this._serviceDefTypes.forEach((serviceDefType) => {
        const serviceDefFactory = this.createServiceDefFactory(serviceDefType, options);
        if (serviceDefFactory) {
          this._serviceDefFactories[serviceDefType] = serviceDefFactory;
        }
      });
    }

    get namespace() {
      return this._namespace;
    }

    get extensionRegistry() {
      return this._extensionRegistry;
    }

    /**
     * Checks if the extension the Services belong to is extended by the given extension.
     *
     * @param {string} namespace Extension id to check.
     * @param {string} [serviceNamespace=this.namespace] Extension id of the services.
     *                                                  Default value is this Services namespace.
     * @returns {boolean}
     */
    _extendsServicesExtension(namespace, serviceNamespace = this.namespace) {
      return this.extensionRegistry && this.extensionRegistry.isDependent(namespace, serviceNamespace);
    }

    /*
     * At the moment...
     * - A "services" (i.e. an instance of this class) is associated with one extension
     * - A "services" manages the services for the extension
     * - A "services" knows about the "services" for the required extensions.
     *    - The "services" of a required extension is called "delegate"
     *    - this._delegates is an array of Services, each associated with an extension that this
     *      extension requires to work
     *    - The list of delegates is created upfront by the application, right after the instantiation of the Services.
     * - When looking for a service, this services looks into its own managed services, then looks into the managed
     *   services of each delegate. This search is done recursively, stopping as soon as the service is found.
     *
     * The intent for the future is to change this algorithm. The goal then would be for the Application to provide a
     * way for clients (like Services) to traverse the required extensions only when needed. The computation of the
     * dependency graph as well the loading of any artifact and/or state would then be done on demand.
     */

    /**
     * @param {Services} services
     * @return Services this services
     */
    addDelegate(services) {
      // this is OK because we will not add services too frequently (at the moment only during the Application loading)
      if (services !== this && !this._delegates.includes(services)) {
        this._delegates.push(services);
      }

      return this;
    }

    /**
     * Asynchronously traverses the delegators until selector returns a truthy result.
     * @param {function(Services):*} selector
     * @return {Promise<*>}
     */
    searchDelegates(selector) {
      return this._delegates.reduce(
        (promise, delegate) => promise.then((result) => result || selector(delegate)),
        Promise.resolve(),
      );
    }

    /**
     * List of service factory types that are used to load services in this Services object.
     * The order is important as the factories will be used in this order to find requested endpoint.
     */
    // eslint-disable-next-line class-methods-use-this
    getServiceDefTypes() {
      return [ProgrammaticPluginFactory.TYPE, ServiceMapFactory.TYPE, ServiceProviderFactory.TYPE];
    }

    /**
     * @param {string} serviceDefType
     * @param {Object} options
     * @returns {ServiceDefFactory}
     * @protected
     */
    createServiceDefFactory(serviceDefType, options = {}) {
      switch (serviceDefType) {
        case ProgrammaticPluginFactory.TYPE: {
          return new ProgrammaticPluginFactory(this, options);
        }
        case ServiceMapFactory.TYPE: {
          return new ServiceMapFactory(this, options);
        }
        case ServiceProviderFactory.TYPE: {
          return new ServiceProviderFactory(this, options);
        }
        default: return null;
      }
    }

    /**
     * Asynchronously traverses the service definition factories until selector returns a truthy result.
     * @param {function(ServiceDefFactory):*} selector
     * @return {Promise<*>}
     */
    searchServiceDefFactories(selector) {
      return this._serviceDefTypes.reduce(
        (promise, type) => promise
          .then((result) => result || (this._serviceDefFactories[type] && selector(this._serviceDefFactories[type]))),
        Promise.resolve(),
      );
    }

    /**
     * @param endpointReference {EndpointReference}
     * @returns {Promise<boolean>} true, means the given ID is declared
     */
    containsDeclaration(endpointReference) {
      return this.isNamespaceMatch(endpointReference.namespace)
        ? this.FindServiceDeclaration(endpointReference).then((declaration) => !!declaration)
        : Promise.resolve(false);
    }

    /**
     * Default impl looks in the map and maybe at the delegate services. Can be overridden to provide a
     * default declaration, when there isn't one.
     *
     * @param {EndpointReference} endpointReference
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {Promise<object|null>}
     * @protected
     */
    FindServiceDeclaration(endpointReference, serverVariables) {
      const serviceName = endpointReference.serviceId;
      // if endpoint has specified namespace (extension Id) use that, via qualifiedServiceId,
      // otherwise use local namecase
      const serviceId = endpointReference.getQualifiedServiceId(this.namespace);

      // check if any of the available service definition factories can provide definition for the requested service
      const findDeclaration = (serviceDefFactory) => (serviceDefFactory.supports(endpointReference)
        ? serviceDefFactory.getDeclaration(endpointReference)
        : Promise.resolve(null));

      return Promise.resolve()
        .then(() => this.searchServiceDefFactories(findDeclaration))
        .then((serviceDeclaration) => {
          if (serviceDeclaration) {
            return Promise.resolve(serviceDeclaration);
          }

          // If the serviceName is not in available here and this is a base services,
          // try to find it in any of the delegates. However, specifically because typically the delegates
          // is the fallbackServices, we need to make sure that serviceName exists because, otherwise,
          // we'd be returning "found" for a service that does not exist.
          if (this.namespace === Constants.ExtensionNamespaces.BASE) {
            const promise = this.searchDelegates(
              (delegate) => delegate.LoadService(serviceId, false, endpointReference, serverVariables)
                .then((result) => (result && result.name === serviceName
                  ? delegate.FindServiceDeclaration(endpointReference, serverVariables)
                  : null)),
            );

            return promise
              .catch((error) => {
                logger.error(`Delegate services failed to load service ${serviceId}`, error);
                return null;
              });
          }

          return Promise.resolve(null);
        });
    }

    /**
     * Add an instance of ServiceProvider that needs to be registered to provide the service
     * @param serviceProvider
     */
    addServiceProvider(serviceProvider) {
      if (serviceProvider instanceof ServiceProvider) {
        const serviceName = serviceProvider.getServiceName();
        if (!serviceName) {
          const error = new Error('The serviceProvider does not provide a service name.');
          logger.error(error);
          throw error;
        }
        if (!serviceProvider.getServiceFilePath()) {
          const error = new Error('The serviceProvider does not provide a service file path.');
          logger.error(error);
          throw error;
        }
        if (!serviceProvider.getDefinition()) {
          const error = new Error('The serviceProvider does not provide a service definition.');
          logger.error(error);
          throw error;
        }

        // use our namespace
        if (this._loadedServicePromises.has(serviceName, this.namespace)) {
          const error = new Error(`A service with name ${serviceName} is already registered.`);
          logger.error(error);
          throw error;
        }

        const serviceProviderServiceDefFactory = this._serviceDefFactories.serviceProvider;
        if (!serviceProviderServiceDefFactory) {
          const error = new Error(`ServiceProviders are not supported in the ${this._relativePath} services.`);
          logger.error(error);
          throw error;
        }
        serviceProviderServiceDefFactory.addServiceProvider(serviceProvider);
      } else {
        logger.error('Given serviceProvider is not an instance of ServiceProvider');
      }
    }

    /**
     * This is used only during testing when Services.load() is called.
     *
     * @returns {Promise<Object[]>}
     */
    loadAll() {
      // iterate over all service definition factories and load all of the definition they support
      const defLoader = (serviceDefinitions) => {
        serviceDefinitions.forEach((serviceDefinition) => {
          if (serviceDefinition) {
            this._loadedServicePromises.set(serviceDefinition.name, undefined, Promise.resolve(serviceDefinition));
          }
        });
        return false;
      };
      return this.searchServiceDefFactories((defFactory) => defFactory.loadAllDefinitions().then(defLoader));
    }

    /**
     * @param {string[]} [namesToLoad] array of keys/names. if passed, limit loading to only these
     * @param {boolean} [forceReload] by default, services are not reloaded if previously loaded
     * @param {EndpointReference} [endpointReference]
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {Promise<Object[]>} resolved to an Array of ServiceDefinition objects, for only the 'namesToLoad'
     */
    load(namesToLoad, forceReload, endpointReference, serverVariables) {
      if (!namesToLoad) {
        return this.loadAll();
      }

      return Promise.all(namesToLoad.map((name) => this
        .LoadService(name, forceReload, endpointReference, serverVariables)));
    }

    /**
     *s
     * @param serviceId
     * @param {boolean} [forceReload] optional. by default, services are not reloaded if previously loaded
     * @param {EndpointReference} [endpointReference]
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {Promise<Object>} resolved to a ServiceDefinition object
     * @protected
     */
    LoadService(serviceId,
      forceReload,
      endpointReference = new EndpointReference(`${serviceId}/any`, { extensionId: this.namespace }),
      serverVariables) {
      const keys = serverVariables && Object.keys(serverVariables).sort();
      const serverVariablesKey = keys && keys.length > 0
        ? keys.map((key) => `${key}:${serverVariables[key]}`).join()
        : undefined;

      // for programmatic endpoints we can not use serviceName for caching as it can be either the backend name
      // (@backendName#module/op) or the service type (serviceType#module/op). Also if backend name for
      // a programmatic endpoint happens to be the same as some existing service name, those keys would cache.
      let serviceKey = endpointReference.serviceName;
      if (endpointReference.isProgrammatic) {
        if (endpointReference.backendName) {
          serviceKey = `@${endpointReference.backendName}`;
        } else {
          serviceKey = `#${endpointReference.serviceType}`;
        }
      }

      // if new providers have been added, make sure we load those
      return Promise.resolve()
        .then(() => {
          // skip if not forReload and its already in the map
          if (forceReload
            || !this._loadedServicePromises.hasWithVariables(serviceKey, undefined, serverVariablesKey)) {
            return Promise.all([false, this.FindServiceDeclaration(endpointReference, serverVariables)]);
          }
          return [true, null];
        })
        // declaration is expected (and used) only when service def is not loaded and we need it to request the loading
        .then(([loaded, declaration]) => {
          if (!loaded) {
            if (!declaration) {
              logger.info('service:', serviceId, 'not found in container', this._relativePath, 'continuing');
            } else {
              // load service declaration based on its type. It may be other then "path"
              const type = declaration.type;
              const serviceDefFactory = this._serviceDefFactories[type];

              // declaration will be { path: '...', <headers: {...}> }

              // allow for an alternate 'proxyName' as an internal workaround for when the declaration name
              // does not match the name of the service in the proxy url.
              // const serviceName = endpointReference.serviceName;

              // Note: This _loadedServicePromises cache is very inefective for services which endpoint calls
              // have per-call server variables that constantly change. In that situation, we would keep loading
              // definitions using new variables, while old (never to be used again) definitions will be kept in
              // the cache.

              // check again to avoid two concurrent requests triggering same load
              if (forceReload
                || !this._loadedServicePromises.hasWithVariables(serviceKey, undefined, serverVariablesKey)) {
                // open and validate the swagger
                this._loadedServicePromises.setWithVariables(serviceKey, undefined, serverVariablesKey,
                  serviceDefFactory.loadDefinition(endpointReference, declaration, serverVariables));
              }
            }
          }

          // wait for the current loads, and then keep waiting for any new loads, if needed
          return this._loadedServicePromises.getWithVariables(serviceKey, undefined, serverVariablesKey);
        });
    }

    /**
     * Loads service metadata file from the given path.
     *
     * @param {string} path
     * @param {Object} [requestInit] configuration for the fetch() Request object used to get the definition
     * @returns {Promise<Object>} Promise of the loaded service definition JSON object
     */
    fetchServiceDefinition(path, requestInit = {}) {
      // remove 'dots' in the middle of the path
      const url = path.startsWith('text!') ? path : URI(path).normalizePath().toString();
      let fetchPromise = this._fetchedServiceMetadataPromises[url];
      if (!fetchPromise) {
        fetchPromise = Promise.resolve()
          .then(() => Utils.getRuntimeEnvironment())
          .then((env) => env.getServiceDefinition(url, requestInit))
          .catch((e) => {
            // we are re-throwing the error, no need to log the error level message
            logger.info('failed to load serviceDefinition for metadata url', path, e);
            throw e; // rethrow
          });

        this._fetchedServiceMetadataPromises[url] = fetchPromise;
      }
      return fetchPromise;
    }

    /**
     * Gets the endpoint from the service definition matching endpoint service ID.
     * This method will not load service. It assumes one was already loaded if it exists.
     *
     * @param {EndpointReference} endpointReference
     * @param {Object} serverVariables
     * @param {ServiceDefinition} [serviceDef] Optional service definiton to get the endpoint from.
     *        This is passed in only to avoid search for one if the calling context already has it.
     * @returns {Promise<Endpoint>}
     */
    // eslint-disable-next-line class-methods-use-this
    _getServiceEndpoint(endpointReference, serverVariables, serviceDef) {
      return Promise.resolve()
        .then(() => {
          // in case we were not given service definition, luuk for it in the cache
          if (!serviceDef) {
            const keys = serverVariables && Object.keys(serverVariables);
            const serverVariablesKey = keys && keys.length > 0
              ? keys.map((key) => `${key}:${serverVariables[key]}`).join()
              : undefined;
            return this._loadedServicePromises
              .getWithVariables(endpointReference.serviceName, undefined, serverVariablesKey);
          }
          return serviceDef;
        })
        // Endpoints are now cached by service definitions, so just get the endpoint
        .then((sd) => sd && sd.findEndpoint(endpointReference, serverVariables));
    }

    /**
     * first, loads the service definition (swagger/openapi) referenced by the endpoint ID.
     * then finds the endpoint, and calls endpoint.load(), to load any extensions (transforms).
     * returns the Endpoint if found, even when the transforms module is not loaded properly
     * @param {EndpointReference} endpointReference
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {Promise<Endpoint|null>}
     */
    getEndpoint(endpointReference, serverVariables) {
      // if we get a string for some reason, convert it
      if (typeof endpointReference === 'string') {
        // eslint-disable-next-line no-param-reassign
        endpointReference = new EndpointReference(endpointReference);
      }

      // only load the requested service
      return Promise.resolve()
        .then(() => {
          if (!this.isNamespaceMatch(endpointReference.namespace)) {
            return false;
          }
          const serviceId = endpointReference.getQualifiedServiceId(this.namespace);
          return this.LoadService(serviceId, false, endpointReference, serverVariables);
        })
        .then((serviceDef) => {
          if (serviceDef && this.isServiceAccessible(serviceDef, endpointReference)) {
            return this._getServiceEndpoint(endpointReference, serverVariables, serviceDef);
          }
          return null;
        })
        .then((endpoint) => {
          if (endpoint) {
            return endpoint.load();
          }
          // do this only for base service (application's) that has FallbackServices as delegate
          if (this.namespace === Constants.ExtensionNamespaces.BASE) {
            return this.searchDelegates((delegate) => delegate.getEndpoint(endpointReference, serverVariables));
          }
          return null;
        })
        .catch((e) => {
          logger.error('Error loading endpoint', endpointReference, e);
          return null;
        })
        .then((ep) => ep || null);
    }

    /**
     * we match if EITHER:
     *  - there's no namespace in the endpoint ID
     *  - there is a namespace in the endpoint ID, and it matches ours
     * @param namespace
     * @returns {boolean}
     */
    isNamespaceMatch(namespace) {
      return this.namespace === namespace || !namespace;
    }

    /**
     * Checks if the endpoint reference can potentially resolve into a service that is accessible.
     * This is only called for not fully qualified endpoint references.
     *
     * @param {EndpointReference} endpointReference
     * @returns {boolean}
     */
    isEndpointReferenceCompatible(endpointReference) {
      if (this.isNamespaceMatch(endpointReference.namespace)) {
        // container namespace
        const contNs = endpointReference.containerNamespace;
        // referenceing namespace
        const refNs = endpointReference.referencingNamespace;

        if ((this.namespace === contNs) || (this.namespace === refNs)) {
          // endpoint is referenced from a container that matches our extension (namespace)
          // so we may be able to find that endpoint
          return true;
        }

        // // calling context is in extension that extends ours
        return (this._extendsServicesExtension(contNs)
          || ((contNs !== refNs) && this._extendsServicesExtension(refNs)));
      }
      return false;
    }

    /**
     * Checks if the service found in the given namespace is accessible from the context
     * of an endpoint reference.
     *
     * @param {string} containerNs Container namespace the endpoint is created in.
     * @param {string} referencingNs Referencing (container) namespace the endpoint is created in.
     * @param {string} [serviceNs=this.namecase] Namespace of the service.
     * @returns {boolean} true if the extension of the container or referencing container extends the
     *                          extension service is found in.
     */
    _isServiceExtensionAccessible(containerNs, referencingNs, serviceNs = this.namespace) {
      // calling context is in extension that extends ours
      if (containerNs === serviceNs || this._extendsServicesExtension(containerNs, serviceNs)) {
        return true;
      }
      // check the referencing namespace too if different from the container
      return ((referencingNs !== containerNs)
        && (referencingNs === serviceNs || this._extendsServicesExtension(referencingNs, serviceNs)));
    }

    /**
     * Discovered service can be used only if one of the following is true:
     * - the extension of this services is the same one using the endpoint
     * - the service definition of the endpoint indicates that it's accessible to other extensions
     *
     * @param {ServiceDefinition} service
     * @param {EndpointReference} endpointReference A reference to an endpoint isnide this extension.
     * @returns {boolean}
     */
    isServiceAccessible(service, endpointReference) {
      const containerNs = endpointReference.containerNamespace;
      const referencingNs = endpointReference.referencingNamespace;

      if ((this.namespace === containerNs) || (this.namespace === referencingNs)) {
        // endpoint is referenced from a container that matches our extension (namespace)
        return true;
      }

      // check if service is available to any extension that depends on these services extension
      if (service.isExtensionAccessible() && this._isServiceExtensionAccessible(containerNs, referencingNs)) {
        return true;
      }

      // the service is avaiable on any of the extensions that depend on the extensions the service
      // gave public access to
      const promotedTo = service.getExtensionProperty('publicAccess');
      if (promotedTo && Array.isArray(promotedTo)) {
        return promotedTo.some((ns) => this._isServiceExtensionAccessible(containerNs, referencingNs, ns));
      }

      return false;
    }

    /**
     * create a path relative to the container, if needed.
     * We will only prepend the container path if either:
     *  - (a) we are loaded by the Application, OR
     *  - (b) we start with a '.' (dot)
     *
     * case (a) insures that Application service paths work they always have - they are always prepended
     * (which for an app-level service, should be a no-op, but just in case. it comes up in unit tests).
     * @param {string} filePath
     * @returns {string}
     */
    getDefinitionPath(filePath) {
      // don't use a relative container path that is a lonely slash ('/') as a prefix (not sure why this happens).
      const prefix = this._relativePath !== '/' ? (this._relativePath || '') : '';

      let resolvedPath;

      //
      // allow requireJS to map the path, if applicable
      //
      // check if its mapped, by seeing if requireJS gives us some new url, or just puts the base on it
      if (filePath[0] !== Constants.RELATIVE_FOLDER_PREFIX) {
        resolvedPath = ServiceUtils.resolveResourcePath(filePath, prefix, true);

        // log a warning when a (non-app-flow) flow uses a path without a "./";
        // using a path like "some/path/service.json" is strange within a flow, because flow's can't
        // reach outside of themselves, but the meaning of that path is ambiguous
        if (!this._isUnrestrictedRelative && resolvedPath.startsWith(prefix)) {
          logger.warn('deprecated: service definition path without current folder prefix is ambiguous:', filePath);
        }
      } else {
        //
        // for relative paths (not absolute, not protocol/host), that path doesn't start with a '/',
        // add the current container prefix.
        //
        resolvedPath = `${prefix}${filePath}`;
      }

      return resolvedPath;
    }

    /**
     * don't allow dot-dot in paths unless its declared in the Application
     * @param filePath
     * @returns {*}
     */
    isPathAllowed(filePath) {
      const allowed = (this._isUnrestrictedRelative || filePath.indexOf(Constants.PARENT_FOLDER) === -1);
      if (!allowed) {
        logger.error('Found invalid service definition path (use of', Constants.PARENT_FOLDER, '):', filePath);
      }
      return allowed;
    }

    /**
     * used by runtimeManager to remove a service, so it can be reloaded
     * @param {string} serviceId
     */
    disposeService(serviceId) {
      this._loadedServicePromises.set(serviceId, undefined, null);
      this._fetchedServiceMetadataPromises = {};
    }

    dispose() {
      this._loadedServicePromises.delete(() => true);
      this._fetchedServiceMetadataPromises = {};
    }

    /**
     * used to evaluate path expressions, on-demand (we used to evaluate them all at once, initially)
     *
     * @param path
     * @returns {*}
     */
    evaluatePathDeclaration(path) {
      return Expression.getEvaluated(path, this._expressionContext);
    }
  }

  return Services;
});

