/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/services/serviceResolver',[
  'vb/private/constants',
], (
  Constants,
) => {
  // VBS-29855: leave it as 'extensionId' property for now
  const extensionIdSymbol = 'extensionId'; // Symbol('service.extensionId');
  class ServiceContext {
    constructor(extensionId) {
      // Related to VBS-29855: if we do not provide property with a setter
      // AggregateServiceDataProvider receives an empty vbContext object.
      // It was verified that vbContext AggregateServiceDataProvider receives is writable,
      // indicating someone is cloning it somewhere on the stack.
      this[extensionIdSymbol] = extensionId;

      Object.freeze(this);
    }

    static getNamespace(serviceContext) {
      return serviceContext[extensionIdSymbol];
    }
  }

  class ServiceContextFactory {
    static getServiceContext(extensionId = Constants.ExtensionNamespaces.BASE) {
      if (!ServiceContextFactory.contextCache[extensionId]) {
        ServiceContextFactory.contextCache[extensionId] = new ServiceContext(extensionId);
      }
      return ServiceContextFactory.contextCache[extensionId];
    }
  }

  ServiceContextFactory.contextCache = {};

  class ServiceResolver {
    static getResolverForExtension(extensionId) {
      return ServiceContextFactory.getServiceContext(extensionId);
    }

    static getResolverForContainer(container) {
      return ServiceResolver.getResolverForExtension(container && container.extensionId);
    }

    /**
     *
     * @param {string|Container|ServiceContext} contextObject
     * @returns
     */
    static getResolver(contextObject) {
      if (contextObject) {
        if (ServiceResolver.checkResolver(contextObject)) {
          return contextObject;
        }

        if (typeof contextObject === 'string') {
          return ServiceResolver.getResolverForExtension(contextObject);
        }

        return ServiceResolver.getResolverForContainer(contextObject);
      }
      return ServiceResolver.getResolverForExtension(undefined);
    }

    /**
     * Check if the given object was created by ServiceResolver.getResolver*().
     *
     * @param {ServiceContext} serviceContext
     * @returns {boolean}
     */
    static checkResolver(serviceContext) {
      return serviceContext && (serviceContext.constructor === ServiceContext.prototype.constructor);
    }

    /**
     *
     * @param {*} serviceContext
     * @param {boolean} strict
     * @throws When strict parameter is true and serviceContext is not created by this class.
     */
    static getNamespace(serviceContext, strict) {
      const validServiceResolver = ServiceResolver.checkResolver(serviceContext);
      // Note that during VBS-29855 investigation it was identified that the vbContext AggregateServiceDataProvider
      // receives is writable indicating someone is cloning it somewhere on the stack.
      // We need to be careful handling that and transitioning clients of that kind of behavior.
      if (strict && !validServiceResolver) {
        throw new Error('Invalid service resolver object');
      }

      if (serviceContext) {
        // we already check this so use it if it is true
        if (validServiceResolver) {
          return ServiceContext.getNamespace(serviceContext);
        }

        if (serviceContext.extensionId) {
          return serviceContext.extensionId;
        }
      }
      return Constants.ExtensionNamespaces.BASE;
    }
  }
  return ServiceResolver;
});

